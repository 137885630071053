import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { 
  PlayCircleOutlined, 
  PauseCircleOutlined, 
  AudioOutlined, 
  UpOutlined,
  DownOutlined,
  EyeOutlined, 
  EyeInvisibleOutlined 
} from "@ant-design/icons";

const AudioVisualizer = ({ link, transcription,colorCode }) => {

  const audioRef = useRef();
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showTranscription, setShowTranscription] = useState(false); // Toggle state


  useEffect(() => {
    if (!audioRef.current) return;

    wavesurferRef.current = WaveSurfer.create({
      container: audioRef.current,
      waveColor: "#ccc",
      progressColor: colorCode,
      cursorWidth: 0,
      barWidth: 3,
      barHeight: 1.5,
      barGap: 2,
      height: 40,
      interact: true,

    });

    wavesurferRef.current.load(link);

    wavesurferRef.current.on("finish", () => setIsPlaying(false));

    return () => wavesurferRef.current.destroy();
  }, [link]);

  const togglePlay = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  return (
  
    <div className="mb-2 text-white px-2 py-2 max-w-[50%] relative">
    <div className="flex items-center">
      <button
        className="text-purple-500 hover:text-purple-700 mr-3"
        onClick={togglePlay}
        >
        {isPlaying? (
          <PauseCircleOutlined style={{ fontSize: "24px" }} />
        ) : (
          <PlayCircleOutlined style={{ fontSize: "24px" }} />
        )}
      </button>
      {/* Ensure ref is properly assigned */}
   

    <div ref={audioRef} className="w-32 h-8"/>

  </div>
  </div>
  );
};

export default AudioVisualizer;
