import { Form, Input, Select, Button, Card, Avatar, Typography } from "antd";
import { UserOutlined, MailOutlined, PhoneOutlined, GlobalOutlined, MessageOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Title, Text } = Typography;

const ChatOnboardingForm = ({
  handleNameChange,
  handleEmailChange,
  handlePhoneChange,
  handleCountryChange,
  handleSubmit,
  selectedCountry,
  countryOptions,
  isLoading,
  colorCode,
}) => {
  return (
    <Card
      className="shadow-md"
      style={{
        maxWidth: 380,
        margin: "0 auto",
        padding: 24,
        background: "white",
        borderRadius: 12,
        textAlign: "center",
        marginTop:'4px'
      }}
    >
  
      <Title level={3} className="text-gray-800">
        👋 Let's Get Started
      </Title>
      <Text className="text-gray-500">Tell us a little about yourself so we can assist you better!</Text>

      <Form layout="vertical" className="mt-4">
        {/* Name Input */}
        <Form.Item label="Your Name" name="name">
          <Input
            prefix={<UserOutlined className="text-gray-400" />}
            placeholder="What's your name?"
            className="rounded-lg"
            size="large"
            onChange={handleNameChange}
          />
        </Form.Item>

        {/* Email Input */}
        <Form.Item label="Email Address" name="email">
          <Input
            prefix={<MailOutlined className="text-gray-400" />}
            type="email"
            placeholder="Where can we reach you?"
            className="rounded-lg"
            size="large"
            onChange={handleEmailChange}
          />
        </Form.Item>

        {/* Phone Input */}
        <Form.Item label="Phone Number" name="phone">
          <Input
            prefix={<PhoneOutlined className="text-gray-400" />}
            placeholder="e.g., +1234567890"
            className="rounded-lg"
            size="large"
            onChange={handlePhoneChange}
            onFocus={(e) => (e.target.placeholder = "e.g., +1234567890")}
            onBlur={(e) => (e.target.placeholder = "")}
          />
        </Form.Item>

        {/* Country Select */}
        <Form.Item name="country">
          <Select
            value={selectedCountry}
            onChange={handleCountryChange}
            placeholder="Where are you located?"
            size="large"
            className="rounded-lg"
            suffixIcon={<GlobalOutlined className="text-gray-400" />}
          >
            {countryOptions.map((country, index) => (
              <Option key={index} value={country}>
                {country}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Start Chat Button */}
        <Form.Item>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => handleSubmit("user")}
            loading={isLoading}
            className="rounded-lg"
            style={{
              backgroundColor: colorCode || "#7F28F8",
              borderColor: colorCode || "#7F28F8",
              fontWeight: "600",
            }}
          >
            💬 Start Chatting
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ChatOnboardingForm;
