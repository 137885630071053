

const config = {
    apiUrl:'https://jawebcrm.onrender.com/api/', 
    websocketUrl:'ws://localhost:8000/ws/',

  };
  console.log(`Running in ${config.environment} mode.`);

  export default config;


