import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import ReactWhatsapp from 'react-whatsapp';
import OrderCard from './components/OrderCard';
import EmailCard from './components/EmailCard';
import countryOptions from './components/Countries';
import { Avatar, Input,Spin,Space ,Inpu} from 'antd';
// import { Input, Spin } from 'antd';
import { CloseOutlined,CloseCircleOutlined,CheckCircleOutlined,LoadingOutlined,PauseCircleOutlined,PlayCircleOutlined } from '@ant-design/icons'; // Import the loading icon
import BouncingDotsLoader from './components/Bouncing';
// import 'tailwindcss/tailwind.css';
import ShadowWrapper from './wrapper';
import BouncingDotsLoaderTyping from './components/BouncingTyping';
import CalendlyEmbeds from './components/CalendlyEmbeds';
import ChatInput from './components/ChatInput';
import ChatMessage from './components/ChatMessage';
import chatbotIcon from './assets/images/download__2_-removebg-preview.png'
import config from './config';
import UserForm from './components/FormComp';
import WaveSurfer from "wavesurfer.js";
import AudioVisualizer from './components/AudioMessage';

function App() {
  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  const [isChatboxOpen, setChatboxOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [display, setDisplay] = useState('block');
  const [calendlyLink,setCalendly]=useState('')

  const [userMessage, setUserMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sessionId, setSessionId] = useState();
  const [createNew, setCreateNew] = useState(false);
  const [isUploadingImage,setIsUploadingImage]=useState(false)
  const [newUser, setNewUser] = useState(() => {
    const cookieValue = getCookie('new_user');
    return cookieValue === 'true';
  });

  const [selectedImage, setSelectedImage] = useState(null); 
  const [audioBlob,setAudioBlob]=useState(null)
  const [selectedCountry, setSelectedCountry] = useState('');
  const [messages, setMessages] = useState([]);
  const [sessionMessages, setsessionMessages] = useState([]);
  const inputRef = useRef(null);
  const [waiting, setWaiting] = useState(false);
  const [ChatLogWaiting,setChatLogWaiting]=useState(false);
 const [isFetchingDetails,setIsFetchingDetails]=useState(false);
  const [client_name, setClientName] = useState('');
  const [user_mail, setUsermail] = useState('');
  const [phone, setPhone] = useState('');
  const [messagesSuggestions, setMessagesSuggestions] = useState([]);
  const [msgSuggestionHeight, setMsgSuggestionHeight] = useState();
  const [restSuggestions, setRestSuggestions] = useState([]);
  const [choicesList, setChoicesList] = useState([]);
  const [showAdminSuggestions, setShowAdminSuggestions] = useState(false);
  const [colorCode, setColorCode] = useState('#000000');
  const [disableForm, setDisableForm] = useState(true);
  const [chatbotLogo, setChatbotLogo] = useState('');
  const [sessionCreated, setSessionCreated] = useState(false);
  const [merchantPhone, setMerchantPhone] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [showTypingIndicator, setShowTypingIndicator] = useState(!isSubmitted);
  const [disableChatbot, setDisableChatbot] = useState(false);
  const [disableChatbotGlobally, setDisableChatbotGlobally] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [PoweredByJaweb,setRemovePoweredByJaweb]= useState(true)
  const [isChatlogCreated, setIsChatlogCreated] = useState(false)
  const [InitialMessageText,setInitialMessageText]=useState("")
  const [playingMessageId, setPlayingMessageId] = useState(null);
  const [Subscription,setSubscription]=useState(false)
  const [refreshKey, setRefreshKey] = useState(0); 

  


  const isFirstVisit = () => {
    return !localStorage.getItem('initialMessageSent');
  };
  
  const setInitialMessageFlag = () => {
    localStorage.setItem('initialMessageSent', 'true');
  };

  const resetInitialMessageFlag = () => {
    localStorage.removeItem('initialMessageSent');
  };
  
  const refreshApp = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleNameChange = (value) => {
    setClientName(value.target.value);
  };

  const handleEmailChange = (value) => {
    setUsermail(value.target.value);
  };

  const handlePhoneChange = (value) => {
    setPhone(value.target.value);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const MAX_RETRIES = 3; 
  const RETRY_DELAY = 2000;


  const fetchData = async (retryCount = 0) => {
    let company_name = getCookie('company_username')
    setIsFetchingDetails(true)
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const params = {
      params: {
        username: company_name,
      },
    };

    try {
      const chatbotDetailsResponse = await axios.get('https://jawebcrm.onrender.com/api/chatbot-details/', params, headers);
      setDisableForm(chatbotDetailsResponse.data.data.chatbot_form);
      setColorCode(chatbotDetailsResponse.data.data.chatbot_color);
      setChatbotLogo(chatbotDetailsResponse.data.data.chatbot_logo);
      setDisableChatbotGlobally(chatbotDetailsResponse.data.data.disable_chatbot_globally);
      setRemovePoweredByJaweb(chatbotDetailsResponse.data.data.remove_powered_by_jaweb)
      setInitialMessageText(chatbotDetailsResponse.data.data.chatbot_initial_msg)
      setSubscription(chatbotDetailsResponse.data.data.status)

    

      if (chatbotDetailsResponse.data.data.calendly_link != null) {
        setCalendly(chatbotDetailsResponse.data.data.calendly_link);
      }

      if (chatbotDetailsResponse.data.data.phone_number_id != null) {
        setMerchantPhone(`+${chatbotDetailsResponse.data.data.phone_number_id}`);
      }




      if (isFirstVisit()) {
        setInitialMessageFlag();

        setMessages((prevMessages) => {
          const updatedMessages = [{ role: 'assistant', content: chatbotDetailsResponse.data.data.chatbot_initial_msg,sender:'Ai Agent'  },...prevMessages];
          storeMessagesInCookies(updatedMessages);
          return updatedMessages;
      });
      setInitialMessageFlag();
      }
    

      // Fetch chatbot plugin suggestions
      const suggestionsResponse = await axios.get('https://jawebcrm.onrender.com/api/chatbot-plugin-suggestions/', params, headers);
      const suggestions = suggestionsResponse.data.data;
      
      if (suggestions && suggestions.length > 0) {
        let firstList = suggestions.slice(0, 3);
        let secondList = suggestions.slice(3);
        
        setMessagesSuggestions(firstList);
        setRestSuggestions(secondList);
      } else {
        setMessagesSuggestions([]);
        setRestSuggestions([]);
      }

      setIsFetchingDetails(false)
    } catch (error) {
      console.error('Error fetching data:', error);

      if (retryCount < MAX_RETRIES) {
        setTimeout(() => fetchData(retryCount + 1), RETRY_DELAY); // Retry after a delay
        setIsFetchingDetails(true)
      } else {
        console.error('Max retries reached. Could not fetch data.');
        // Handle max retry failure (e.g., show a message to the user)
      }
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  const [showMessage, setShowMessage] = useState(false);

 

  useEffect(() => {
    // When the chatbox is opened, hide the message and mark it as seen
    if (isChatboxOpen) {
      setShowMessage(false);
    }
  }, [isChatboxOpen]);


  const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  const generateUniqueSessionId = () => {
    return 'session_' + Math.random().toString(36).substring(7);
  };

  useEffect(() => {
    test();
  }, [messagesSuggestions, isChatboxOpen]);

  const test = () => {
    const divheight = document.getElementById('mydiv');
    if (messagesSuggestions.length > 0 && divheight) {
      setMsgSuggestionHeight(divheight.offsetHeight);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const newUserCookie = getCookie('new_user');
    const sessionId = getCookie('session_id');
    
    if (!sessionId) {
      // This means there's no session ID, so the user is treated as a new user
      const newSessionId = generateUniqueSessionId();
      setCookie('session_id', newSessionId, 1); // Set the session_id cookie for 1 day
      setSessionId(newSessionId); // Update state with new session ID
      setNewUser(true); // Mark user as new
      setCreateNew(true); // Flag to create a new session
      setCookie('new_user', "true", 1); // Set new_user cookie as true
      resetInitialMessageFlag();

      if (!isChatboxOpen) {
        setShowMessage(true);
      }

      localStorage.removeItem('message_history');
      localStorage.removeItem('messages_session'); // Initialize an empty messages session
      refreshApp();  // Trigger refresh after creating the session

    } else if (newUserCookie === "true") {
      localStorage.removeItem('message_history');
      localStorage.removeItem('messages_session')
      resetInitialMessageFlag();

      setNewUser(true); // Mark user as new
      setCreateNew(true); // No need to create a new session, session_id exists
      const storedMessages = getMessagesFromCookies(); // Retrieve stored messages from cookies
      setMessages(storedMessages); // Update state with retrieved messages
    } else {
      // If the session ID exists and new_user is not "true", this is an existing user
      setSessionId(sessionId); // Update state with existing session ID
      setNewUser(false); // Mark user as not new
      const storedMessages = getMessagesFromCookies(); // Retrieve stored messages from cookies
      setMessages(storedMessages); // Update state with retrieved messages
      setCreateNew(false); // No need to create a new session
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refreshKey]);


  useEffect(() => {

}, [sessionId, newUser, createNew]);

  const messagesRef = useRef(null);

  const storeMessagesInCookies = (messages) => {
    localStorage.setItem('messages_session',JSON.stringify(messages))
  };

  const getMessagesFromCookies = () => {
    const messagesCookie = localStorage.getItem('messages_session');
    try {
      return messagesCookie ? JSON.parse(messagesCookie) : [];
    } catch (error) {
      console.error('Failed to parse messages from cookies:', error);
      return [];
    }
  };

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleButtonClick = async () => {
    if (!isChatboxOpen) {
      if (disableForm === false) {
        setChatboxOpen(true);

      }
      setDisplay('block');
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setDisplay('none');
        setIsClosing(false);
      }, 15000); 
      setChatboxOpen(false);
    }


    setChatboxOpen(!isChatboxOpen);
  };


  const handleUpdateEmail = async (email) => {
    try {
      const response = await axios.put('https://jawebcrm.onrender.com/api/client/update-chatlog-email/', {
        email,
        sessionid: getCookie('session_id'),
      });
  
      if (response.status === 200) {
        setIsEmailSubmitted(true); // Mark as submitted in the parent component
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, { role: 'user', content: email, isBusiness: true }];
          storeMessagesInCookies(updatedMessages);
          return updatedMessages;
        });
        apiCall(email,false);
      }
    } catch (error) {
      console.error("Error submitting email:", error);
    }
  };

  


  const apiCall = async (userMessage,finalImageUrl,isImage) => {


    if (disableChatbot === false && disableChatbotGlobally === false) {
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, { role: 'assistant', content: 'typing', isBusiness: false, typing: true }];
            storeMessagesInCookies(updatedMessages);
            return updatedMessages;
        });
    }

    try {

      if (isChatlogCreated == false) {
        
        if (createNew && disableForm === true) {
          setChatLogWaiting(true)
    
          try {
    
            const bodyData = JSON.stringify({ name: 'Guest', isActive: true, user_session_id: getCookie('session_id'), company_username:  getCookie('company_username'), mail: 'N/A', country: 'N/A', phone: 'N/A' });
    
            const response = await fetch('https://jawebcrm.onrender.com/api/chatlog-create/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: bodyData,
            });
    
            if (response.ok) {
              // localStorage.setItem('chatlog_created', true);
              setIsChatlogCreated(true)
              const data = await response.json();
              setCookie('new_user', 'false', 1);
              setNewUser(false);
              setCreateNew(false)
              setChatLogWaiting(false);
              setSessionCreated(true);
              localStorage.setItem('message_history', JSON.stringify(data.obj), 1);
              if (!isChatboxOpen) {
                  setChatboxOpen(true);
                setDisplay('block');
              } else {
                setIsClosing(true);
                setTimeout(() => {
                  setDisplay('none');
                  setIsClosing(false);
                }, 15000); 
                // setChatboxOpen(false);
              }
          
            } else {
              console.error('Failed to fetch data from the API');
              setChatLogWaiting(false);
              setCookie('new_user', 'true', 1);
    
            }
          } catch (error) {
            console.error('Error occurred while fetching data:', error);
            setChatLogWaiting(false);
          }
        }

      }


        const messageHistory = JSON.parse(localStorage.getItem('message_history'));


        const response = await fetch('https://jawebcrm.onrender.com/api/message-create/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: userMessage,
                isBusiness: true,
                session_id: getCookie('session_id'),
                organization:  getCookie('company_username'),
                message_history: messageHistory,
                isImage:isImage,
                image_url:finalImageUrl
            }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.message_history) {
                localStorage.setItem('message_history', JSON.stringify(data.message_history));

                const assistantMessage = { role: 'assistant', content: data.message, sender:'Ai Agent',isBusiness: false ,isHtml:data.isHtml};

                if (socket && socket.readyState === WebSocket.OPEN) {
                  const additionalData = { isBusiness: false, SessionId: getCookie('session_id'),sender:'AI',response:'from_ai_web' };
                  const messagePayload = JSON.stringify({
                    message: data.message,
                    additionalData: additionalData,
                    
                  });
                  socket.send(messagePayload);
                }


                setMessages((prevMessages) => {
                    const existingMessage = prevMessages.find(msg => msg.content === data.message);
                    
         
                        let updatedMessages = [...prevMessages.filter((msg) => !msg.typing), assistantMessage];

                        if (/enter\s?your\s?order|enter\s?order|order\s?number/i.test(data.message)) {
                            updatedMessages = [
                                ...updatedMessages,
                                { role: 'assistant', content: 'OrderCard', isBusiness: false, isOrderCard: true },
                            ];
                        }

                        if (/enter\s?your\s?email|enter\s?email|email\s?address|give\s?us\s?your\s?email\s?address|please\s?give\s?us\s?your\s?email/i.test(data.message)) {
                          updatedMessages = [
                              ...updatedMessages,
                              { role: 'assistant', content: 'EmailCard', isBusiness: false, isEmailCard: true },
                          ];
                      }

                      if (data && data.message && calendlyLink) {
                        if (/(book\s?(a\s?)?meeting|schedule\s?(a\s?)?(call|meeting)|let\s?('s)?\s?meet|set\s?up\s?(a\s?)?meeting|book\s?(an\s?)?appointment)/i.test(data.message)) {
                          updatedMessages = [
                            ...updatedMessages,
                            { 
                              role: 'assistant', 
                              content: 'Scheduling a meeting...', 
                              isBusiness: false, 
                              callCalendly: true 
                            },
                          ];
                      
                     
                        }
                      }
                      

                        storeMessagesInCookies(updatedMessages);
                        return updatedMessages;
                   

                    return prevMessages;
                });
            } else {
  
              if (data.status === "success") {

                  setMessages((prevMessages) => {
                      // Remove only messages with `typing: true`
                      const updatedMessages = prevMessages.filter((msg) => !msg.typing);
      
                      storeMessagesInCookies(updatedMessages);
                      return updatedMessages;
                  });
              }
            }
            scrollToBottom();
        } else {
            console.error('Failed to fetch data from the API');
        }
    } catch (error) {
        console.error('Error occurred while fetching data:', error);
    }
};

  const apiCallChatlog = async (type) => {
    setChatLogWaiting(true);

    try {
      const bodyData = JSON.stringify({ name: client_name, isActive: true, user_session_id: getCookie('session_id'), company_username: getCookie('company_username'), mail: user_mail, country: selectedCountry, phone: phone });

      const response = await fetch('https://jawebcrm.onrender.com/api/chatlog-create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyData,
      });

      if (response.ok) {
        const data = await response.json();
        setCookie('new_user', false, 1);
        setNewUser(!getCookie('new_user'));
        setChatLogWaiting(false);
        localStorage.setItem('hasName', true);
        localStorage.setItem('message_history', JSON.stringify(data.obj), 1);
      } else {
        console.error('Failed to fetch data from the API');
        setChatLogWaiting(false);
      }
    } catch (error) {
      console.error('Error occurred while fetching data:', error);
      setChatLogWaiting(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {

    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const newSocket = new WebSocket(`wss://jawebcrm.onrender.com/ws/chat/${getCookie('session_id')}/`);
    newSocket.onopen = () => {
      setSocket(newSocket);
    };

    newSocket.onmessage = (event) => {
      
      const data = JSON.parse(event.data);

    
      const message = data.message;
      const isBusiness = data.additionalData.isBusiness;
      const sender=data.additionalData.sender


      if (data.additionalData.disableChatbot) {
        setDisableChatbot(data.additionalData.disableChatbot)
      }

      if (!isBusiness && sender!=="AI") {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, { role: 'assistant', content: message, isBusiness: false, sender:sender }];
          storeMessagesInCookies(updatedMessages);
          return updatedMessages;
        });
      }
      
    
    };

    newSocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event.reason);
    };

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [getCookie('session_id')]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const userMessage = e.target.value;
      e.target.value = '';
      setWaiting(true);

      if (socket && socket.readyState === WebSocket.OPEN) {
        const additionalData = { isBusiness: true, SessionId: sessionId };
        const messagePayload = JSON.stringify({
          message: userMessage,
          additionalData: additionalData,
        });
        socket.send(messagePayload);
      }

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, { role: 'user', content: userMessage, isBusiness: true }];
        storeMessagesInCookies(updatedMessages);
        return updatedMessages;
      });

      scrollToBottom();
      apiCall(userMessage,null,false);
      setUserMessage('');
    }
  };

  const handleButtonPress = async () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const additionalData = { isBusiness: true, SessionId: sessionId };
      const messagePayload = JSON.stringify({
        message: userMessage,
        additionalData: additionalData,
      });
      socket.send(messagePayload);
    }

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, { role: 'user', content: userMessage, isBusiness: true,sender:'Ai Agent' }];
      storeMessagesInCookies(updatedMessages);
      return updatedMessages;
    });

    scrollToBottom();
    apiCall(userMessage,null,false);
    setUserMessage('');
  };

  const parseMessage = (message,isHtml) => {
    const imageRegex = /\bhttps?:\/\/[^\s]+files[^\s]+\.(png|jpg|jpeg|gif|svg|webp)(\?[^\s]*)?/gi;
    const shopifyLinkRegex = /\b[a-zA-Z0-9-]+\.myshopify\.com\/\S*track_id=\S*/gi;
    const httpsRegex = /\bhttps?:\/\/[^\s]+/gi;

    if (isHtml) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: message }}
          style={{
            wordWrap: 'break-word',
            fontSize: '13px',
            color: 'black',
          }}
        />
      );
    }
  
   
    const parts = message.split(/(\bhttps?:\/\/[^\s]+)/g);
  
    return parts.map((part, index) => {
      if (part.match(imageRegex)) {
        return (
          <img
            key={`img-${index}`}
            src={part}
            alt="Product Image"
            style={{ maxWidth: '100%', borderRadius: '5px', marginTop: '10px', marginBottom: '10px' }}
          />
        );
      }
      else if (part.match(shopifyLinkRegex)) {
        const properUrl = part.startsWith('http') ? part : `https://${part}`;
        return (
          <a
            key={`link-${index}`}
            href={properUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: '#0d6efd' }}
          >
            (Product Link)
          </a>
        );
      }
      else if (part.match(httpsRegex)) {
        return (
          <a
            key={`link-${index}`}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: 'blue' }}
          >
            (Link)
          </a>
        );
      }
      return <span key={`text-${index}`}>{part}</span>;
    });
  };

  const handleSubmit = async (type) => {
    apiCallChatlog(type);
  };

  const onSuggestedMsgClick = (question) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const additionalData = { isBusiness: true, SessionId: sessionId };
      const messagePayload = JSON.stringify({
        message: question,
        additionalData: additionalData,
      });
      socket.send(messagePayload);
    }

    setMessagesSuggestions(messagesSuggestions.filter((item) => item !== question));
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: question, isBusiness: true },
    ]);
    apiCall(question,null,false);

    if (messagesSuggestions.length < 4 && restSuggestions.length !== 0) {
      let newSuggestion = restSuggestions.pop();
      setMessagesSuggestions((prevMsg) => [...prevMsg, newSuggestion]);
    }
  };


  
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const setupEnvironment = async () => {
      setIsLoading(true); // Start loading
      try {
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 3000));

        // Replace this with your actual chatlogApi logic when done
        // After API call
        setIsLoading(false); // End loading after setup
      } catch (error) {
        console.error("Error setting up environment:", error);
        setIsLoading(false); // End loading even if there's an error
      }
    };

    setupEnvironment();
  }, []);

  
  const [rating, setRating] = useState(0); 
  const [selectedRating, setSelectedRating] = useState(0);
  const [isRated, setIsRated] = useState(false);
  const [storageUpdate, setStorageUpdate] = useState(false);

  useEffect(() => {
    let timer;
  
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!isRated && !localStorage.getItem('is_rated') && messages.length > 6) {
          const feedbackMessage = { isFeedback: true };
  
          const feedbackAlreadyAdded = messages.some((message) => message.isFeedback);
  
          if (!feedbackAlreadyAdded) {
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages, feedbackMessage];
              localStorage.setItem('messages_session', JSON.stringify(updatedMessages));
              return updatedMessages;
            });
  
            setStorageUpdate((prev) => !prev);
          }
        }
      }, 300000); 
    };
  
    resetTimer();
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
  
    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
    };
  }, [isRated, messages]);
  

  
  const handleFeedback = (value) => {
    setSelectedRating(value);
    setRating(0);
    handleSubmitRating(value);
  };

  
  
  const handleSubmitRating = (ratingValue) => {
    if (ratingValue > 0) {
      const formData = new FormData();
      formData.append('rating', ratingValue);
      formData.append('session_id', getCookie('session_id'));
      formData.append('company_name',  getCookie('company_username'));
  
      axios
        .post(`https://jawebcrm.onrender.com/api/chatbot-feedback-rating/`, formData)
        .then(() => {
          localStorage.setItem('is_rated', true);
          setIsRated(true);
  
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.filter((msg) => !msg.isFeedback);
            localStorage.setItem('messages_session', JSON.stringify(updatedMessages));
            return updatedMessages;
          });
  
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: 'assistant', content: 'Thank you for your feedback! 😊', isBusiness: false },
          ]);
        })
        .catch((error) => {
          console.error('Error submitting feedback:', error);
        });
    }
  };
  
  

  
  const handleSendImage = async () => {
    if (!selectedImage) return;
  

  
    const tempImageUrl = URL.createObjectURL(selectedImage);
    const tempMessageId = Date.now();
  
    
    const tempJson = {
      id: tempMessageId,
      message:  "[Image]",
      image_url: tempImageUrl,  // Temporary URL for immediate display
      isBusiness: true,
      status:'uploading'
    };
  
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, tempJson];
      storeMessagesInCookies(updatedMessages);
      return updatedMessages;
    });





    let finalImageUrl = null;
  
    if (selectedImage) {
      console.log("...");
      try {
        const formData = new FormData();
        formData.append("image", selectedImage);
  
        const uploadResponse = await axios.post(
          `${config.apiUrl}upload-image/`,
          formData,
          {
    
              "Content-Type": "multipart/form-data",
            }

        );
  
        if (uploadResponse.status === 201) {
          finalImageUrl = uploadResponse.data.image_url; // URL from S3
  
          // Replace temporary image URL with the actual URL from S3
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.map((msg) =>
              msg.id === tempMessageId ? { ...msg, image_url: finalImageUrl } : msg
            );
            storeMessagesInCookies(updatedMessages);
            return updatedMessages;
          });
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: 'failed' } : msg
        )
      );
        return;
      }
    }


    try {


      if (socket && socket.readyState === WebSocket.OPEN) {
        const additionalData = { isBusiness: true, SessionId: sessionId };
        const messagePayload = JSON.stringify({
          image_url: finalImageUrl || null, 
          message: userMessage||"[Image]",
          additionalData: additionalData,
        });

        socket.send(messagePayload);

      }
  
   
 


      if (finalImageUrl) {
        apiCall(userMessage,finalImageUrl,true)
      }
  
  
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: 'delivered' } : msg
        )
      );


    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
  
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: 'failed' } : msg
        )
      );
    } finally {
      setSelectedImage(null);
      setIsUploadingImage(false);
    }
  };


  const handleAddToCart =(productInfo)=>{
    console.log(productInfo)
     apiCall(`Add this to cart for me ${productInfo}`,null,false)
  }


  const handleSendVoice = async () => {
    if (!audioBlob) return;
  


    const tempAudioUrl = URL.createObjectURL(audioBlob);
    const tempMessageId = Date.now();
  
    // Temporary message with local blob URL
    const tempJson = {
      id: tempMessageId,
      message: "[Audio]",
      audio_url: tempAudioUrl, // Temporary URL
      isBusiness: true,
      status: "uploading",
    };
  
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, tempJson];
      storeMessagesInCookies(updatedMessages);
      return updatedMessages;
    });
  
    let finalAudioUrl = null;
    let transcriptionText = "";
  
    try {
      const formData = new FormData();
      formData.append("audio", audioBlob);
  
      const uploadResponse = await axios.post(
        `${config.apiUrl}upload-audio/`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
  
      if (uploadResponse.status === 201) {
        finalAudioUrl = uploadResponse.data.audio_url; // URL from S3
        transcriptionText = uploadResponse.data.transcription_text; // Transcribed text
  
        // Replace temporary audio URL with actual S3 URL
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((msg) =>
            msg.id === tempMessageId
              ? { ...msg, audio_url: finalAudioUrl, status: "delivered" }
              : msg
          );
          storeMessagesInCookies(updatedMessages);
          return updatedMessages;
        });
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: "failed" } : msg
        )
      );
      return;
    }
  
    try {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const additionalData = { isBusiness: true, SessionId: sessionId };
        const messagePayload = JSON.stringify({
          audio_url: finalAudioUrl || null,
          message: transcriptionText || "[Audio]",
          additionalData: additionalData,
        });
        socket.send(messagePayload);
      }
  
      if (finalAudioUrl) {
        apiCall(transcriptionText, finalAudioUrl, false); // Send transcription + audio
      }
    } catch (error) {
      console.error("Error sending WhatsApp message:", error);
      setMessages((messageList) =>
        messageList.map((msg) =>
          msg.id === tempMessageId ? { ...msg, status: "failed" } : msg
        )
      );
    } finally {
      setAudioBlob(null);
    }
  };
  
  

  const antIcon = <BouncingDotsLoader/>; 

  
  return (


 
     
    <ShadowWrapper>
    {!isFetchingDetails && Subscription && ( // ✅ Show chatbot only when Subscription is true
      <div className='chatbot'>
      <div style={{ position: 'fixed', bottom: 10, right: 10, zIndex: 999 }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          {InitialMessageText && showMessage && !isChatboxOpen &&(
            <div
          style={{
            position: 'absolute',
            bottom: '70px',
            right: '0px',
            backgroundColor: 'white',
            color: '#444',
            padding: '8px 15px',
            borderRadius: '15px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            fontSize: '14px',
            textAlign: 'center',
            animation: 'slideIn 0.6s ease-out', // Adding the animation
          }}
          className='desktop-nowrap'
        >

  <span style={{ display: 'block' }}>
    {InitialMessageText}
  </span>
  <div
    style={{
      content: '""',
      position: 'absolute',
      bottom: '-10px', // Positioning the arrow
      right: '10px', // Centering the arrow
      width: '0',
      height: '0',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid white', // Arrow color matches the bubble
    }}
  />
  {/* Adding CSS animation and media query */}
  <style>
    {`
      @keyframes slideIn {
        0% {
          transform: translateY(20px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
      /* Media query for desktop view */
      @media (min-width: 768px) {
        .desktop-nowrap {
          white-space: nowrap;
        }
      }
    `}
  </style>
</div>
)}
        <button
            style={{
              background: colorCode,
            
              transition: 'box-shadow 0.3s ease',
              boxShadow: '0 0 0 0 rgba(127, 46, 248, 0)',
              display:'flex',
              justifyContent:'center',
              alignItems:'center'
            }}

            className='rounded-full w-14 h-14 flex justify-center items-center'

            onClick={handleButtonClick}
            disabled={isFetchingDetails || ChatLogWaiting} // Disable the button when fetching details or chatlog
            onMouseOver={(e) => (e.currentTarget.style.boxShadow = `0 0 10px 5px ${colorCode}`)}
            onMouseOut={(e) => (e.currentTarget.style.boxShadow = '0 0 0 0 rgba(127, 46, 248, 0)')}
          >
            {isFetchingDetails ? (
              <Spin indicator={<LoadingOutlined/>} spin size="small" /> 
            ) : ChatLogWaiting ? (
              <Spin indicator={<LoadingOutlined/>} size='small'  spin/> 
            ) : isChatboxOpen ? (
              <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z"
                  fill="white"
                ></path>
              </svg>
            ) : (
    
              <img
                src={'https://jaweb.me/wp-content/uploads/2025/03/download__2_-removebg-preview.png'}
                style={{maxWidth:'60%'}}
              />
            )}
          </button>
        </div>

        {(isChatboxOpen) ? (
          <div
            id="chatbox-container"
            style={{
              position: 'fixed',

              bottom: windowWidth < 768 ? '20%' : '',
              right: windowWidth < 768 ? 0 : 10,
              top: windowWidth < 768 ? '0' : '5%' ,
              height: windowWidth < 768 ? '100%' : '85%',
              borderRadius: 10,
              width: windowWidth < 768 ? '100%' : '25%',
              zIndex: 2,
              animation: isChatboxOpen ? 'slideIn 0.7s forwards' : isClosing ? 'slideOut 15s forwards' : 'none',
            }}
          >
            <style>
              {`
                @keyframes slideIn {
                  0% {
                    transform: translateY(100%);
                  }
                  100% {
                    transform: translateY(0);
                  }
                }

                @keyframes slideOut {
                  0% {
                    transform: translateY(0);
                  }
                  100% {
                    transform: translateY(120%);
                  }
                }
              `}
            </style>
            {newUser && !disableForm ? (
              <div
              style={{
                bottom: windowWidth < 768 ? '20%' : '0',
                display:'flex',
                flexDirection:'column',
                right: windowWidth < 768 ? 0 : 10,
                background: 'white',
                height: windowWidth < '20%' ? 0 : '100%',
                borderRadius: windowWidth < 768 ? 0 : 10,
                border: `1px solid ${colorCode}`,
                width: windowWidth < 768 ? '100%' : '100%',
                zIndex: 2,
                animation: isChatboxOpen ? 'slideIn 0.3s forwards' : 'slideOut 0.3s forwards',
              }}
            >
                 <div
                  style={{
                    width: '100%',
                    height: '10%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 10,
                    justifyContent: 'space-between',
                    borderBottom: '1px solid 	#cedad4',
                  }}
                >
                  <div>
                  <Space wrap >
                    <Avatar 
                    src={
                      chatbotLogo
                        ? chatbotLogo
                        : 'https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg'
                    }
                    alt="Logo"
                    size={40}
                    shape="square"
                    className='mr-4 ml-4  rounded'

                    />
                    </Space>

        
                  </div>
                  <div className='flex items-center gap-4'>
                  {merchantPhone && (
                    <ReactWhatsapp
                      style={{
                        backgroundImage: 'url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOSR0a8JWoDysbmRPaCFC3i7B7DkxKZlzBvg&s")',
                        backgroundSize: 'cover',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                      }}
                      number={merchantPhone}
                      message="Would you like to connect?"
                    />
                  )}

                {windowWidth < 768?
                <CloseOutlined className='text-2xl font-bold'
                onClick={handleButtonClick}

                />
                      :<></>
                  }
                  </div>
           
                </div>


                <div>
                  <UserForm 
                  countryOptions={countryOptions}
                  handleNameChange={handleNameChange}
                  handleEmailChange={handleEmailChange}
                  handleCountryChange={handleCountryChange}
                  handleSubmit={handleSubmit}
                  selectedCountry={selectedCountry}
                  isLoading={ChatLogWaiting}
                  colorCode={colorCode}
                  handlePhoneChange={handlePhoneChange}
                  
                />
                </div>

              </div>
            ) : (

              <div
                style={{
                  bottom: windowWidth < 768 ? '20%' : '0',
                  display:'flex',
                  flexDirection:'column',
                  right: windowWidth < 768 ? 0 : 10,
                  background: 'white',
                  height: windowWidth < '20%' ? 0 : '100%',
                  borderRadius: windowWidth < 768 ? 0 : 10,
                  border: `1px solid ${colorCode}`,
                  width: windowWidth < 768 ? '100%' : '100%',
                  zIndex: 2,
                  animation: isChatboxOpen ? 'slideIn 0.3s forwards' : 'slideOut 0.3s forwards',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '10%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 10,
                    justifyContent: 'space-between',
                    borderBottom: '1px solid 	#cedad4',
                  }}
                >
                  <div>
                  <Space wrap >
                    <Avatar 
                    src={
                      chatbotLogo
                        ? chatbotLogo
                        : 'https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg'
                    }
                    alt="Logo"
                    size={40}
                    shape="square"
                    className='mr-4 ml-4  rounded'

                    />
                    </Space>

        
                  </div>
                  <div className='flex items-center gap-4'>
                  {merchantPhone && (
                    <ReactWhatsapp
                      style={{
                        backgroundImage: 'url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOSR0a8JWoDysbmRPaCFC3i7B7DkxKZlzBvg&s")',
                        backgroundSize: 'cover',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                      }}
                      number={merchantPhone}
                      message="Would you like to connect?"
                    />
                  )}

                {windowWidth < 768?
                <CloseOutlined className='text-2xl font-bold'
                onClick={handleButtonClick}

                />
                      :<></>
                  }
                  </div>
           
                </div>

                <div
                  style={{
                    height: '70%',
                    overflowY: 'scroll',
                    padding: 10,
                  }}
                  ref={messagesRef}
                >
                  {messages.map((message, index) => (
                    <div key={index} style={{ marginBottom: 10,display:'flex',flexDirection:'column' }}
                    className={`flex flex-col ${
                      message.isBusiness ? "justify-end" : "justify-start"
                    } mb-4`}
                    
                    >
                    {message.isBusiness ? (
                 <div className="flex flex-col items-end">
                {message?.audio_url ? (
                  <div>
                    <AudioVisualizer link={message?.audio_url} colorCode={colorCode}/>


                    <div className="mb-1 mr-2 flex items-end justify-end">
                      {message.status === "uploading" ? (
                        <Spin indicator={<LoadingOutlined spin />} size="small" />
                      ) : message.status === "delivered" ? (
                        <CheckCircleOutlined style={{ color: "green" }} />
                      ) : message.status === "failed" ? (
                        <CloseCircleOutlined style={{ color: "red" }} />
                      ) : null}
                    </div>
                  </div>
                ) : message?.image_url ? (
                  <div>
                    {/* Image Bubble */}
                    <div className="mb-2 bg-black text-white px-2 py-2 max-w-[50%] relative">
                      <img
                        src={message.image_url}
                        alt="Chat image"
                        style={{ width: 200 }}
                        className={`rounded-md mt-2 max-w-xs ${
                          message?.isBusiness ? "border border-gray-300 shadow-sm" : "border border-green-400 shadow-sm"
                        }`}
                      />
                    </div>

                    {/* Status Indicator */}
                    <div className="mb-1 mr-2 flex items-end justify-end">
                      {message.status === "uploading" ? (
                        <Spin indicator={<LoadingOutlined spin />} size="small" />
                      ) : message.status === "delivered" ? (
                        <CheckCircleOutlined style={{ color: "green" }} />
                      ) : message.status === "failed" ? (
                        <CloseCircleOutlined style={{ color: "red" }} />
                      ) : null}
                    </div>
                  </div>
                ) : (
                  /* Text Message Bubble */
                  <div className="bg-black text-white px-3 py-2 text-sm rounded-full max-w-[50%] break-all whitespace-pre-wrap">
                    <ChatMessage colorCode={colorCode} message={message.content} handleAddToCart={handleAddToCart} />
                  </div>
                )}

               </div>
               
                  ) 
                      :message.callCalendly ? (
                        <div
                          style={{
                         
                            borderRadius: 5,
                            color: 'white',
                            width: '100%',
                          }}
                        >
                          {/* Rendering the Calendly Embed inside the chat */}
                          <CalendlyEmbeds calendlyLink={calendlyLink} />
                        </div>
                      )
                      :message.isFeedback ? (
                        <div
                          style={{
                            padding: 20,
                            borderRadius: 10,
                            background: '#f9f9f9',
                            color: '#333',
                            textAlign: 'center',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                          }}
                        >
                          {!isRated ? (
                            <>
                              <p style={{ marginBottom: 10, fontSize: '16px', fontWeight: 'bold' }}>
                                How would you rate your experience?
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: '5px',
                                  marginBottom: 10,
                                }}
                              >
                                {[1, 2, 3, 4, 5].map((value) => (
                                  <span
                                    key={value}
                                    onClick={() => handleFeedback(value)} // Save the selected rating
                                    onMouseEnter={() => setRating(value)} // Preview on hover
                                    onMouseLeave={() => setRating(0)} // Reset preview when mouse leaves
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '30px',
                                      color: value <= (rating || selectedRating) ? '#ffc107' : '#e4e5e9', // Highlight selected or hovered stars
                                      transition: 'color 0.2s, transform 0.2s',
                                      transform: value === selectedRating ? 'scale(1.2)' : 'scale(1)', // Enlarge selected star
                                    }}
                                  >
                                    ⭐
                                  </span>
                                ))}
                              </div>
                              {selectedRating > 0 && (
                                <p style={{ marginTop: 10, fontSize: '14px', color: '#666' }}>
                                  You selected {selectedRating} star{selectedRating > 1 ? 's' : ''}.
                                </p>
                              )}
                            </>
                          ) : (
                            <p style={{ fontSize: '16px', color: '#333' }}>
                              🎉 Thank you for your feedback! 🎉
                            </p>
                          )}
                        </div>
                      )
                       : message.typing ? (
                        <div className="max-w-[60%] p-3 bg-gray-100 shadow-md border rounded-xl relative" style={{borderColor:colorCode}}>
                          {/* Bot Header */}
                              <div className="flex items-center mb-2">

                                  <Avatar 
                                    src={
                                      chatbotLogo
                                        ? chatbotLogo
                                        : 'https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg'
                                    }
                                    alt="Logo"
                                    size={25}
                                    shape="square"
                                    className='mr-4 ml-4  rounded'

                                    />
                                <span className="text-sm font-semibold text-gray-700">• {message.sender || "Ai Agent" } </span>
                              </div>

                            <Spin className='ml-6' indicator={<BouncingDotsLoaderTyping borderColor={colorCode}/>} />

                          <div className="absolute inset-0 border-2 rounded-xl border-purple-300 opacity-20"></div>
                          </div>
                      ) : message.isOrderCard ? (
                        <OrderCard color={colorCode} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
                      ) : message.isEmailCard ? (
                        <EmailCard 
                        color={colorCode}
                        onSubmit={handleUpdateEmail} 
                        isEmailSubmitted={isEmailSubmitted} 
                        setIsEmailSubmitted={setIsEmailSubmitted} 
                      />
                      ): (


<div
  className="max-w-[60%] p-3 bg-gray-100 shadow-md border rounded-xl "
  style={{ borderColor: colorCode }}
>
  {/* Bot Header */}
  <div className="flex items-center mb-2">
    <Avatar
      src={
        chatbotLogo
          ? chatbotLogo
          : "https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg"
      }
      alt="Logo"
      size={25}
      shape="square"
      className="mr-4 ml-4 rounded"
    />
    <span className="text-sm font-semibold text-gray-700">• {message.sender}</span>
  </div>

  {/* Bot Message */}
  {message?.image_url ? (
    <div className="w-fit mb-2">
      <img
        src={message.image_url}
        alt="Chat image"
        className={`rounded-md mt-2 max-w-xs ${
          message?.isBusiness ? "border border-gray-300 shadow-sm" : "border border-green-400 shadow-sm"
        }`}
      />
    </div>
  ) : (
      <ChatMessage colorCode={colorCode} message={message.content} handleAddToCart={handleAddToCart} />

  )}


</div>
                      )}
                    </div>
                  ))}
                    
                </div>
                <div className="test" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',height:'20%'}}>
                <div className="scroll-wrapper " id="mydiv" style={{ marginBottom: '4px', overflowX: 'auto',width:'94%',}}>
                  <div 
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap:'4px',
                      height: messagesSuggestions.length === 0 ? '0px' : 'auto',
                      padding: '0',
                      scrollbarWidth: 'thin',
                      msOverflowStyle: 'auto',
                    }}
                  >
                    {messagesSuggestions.length > 0 &&
                      messagesSuggestions.map((question, id) => (
                        <div
                          key={id}
                          style={{
                            padding: '6px 10px',
                            border: `1px solid ${colorCode}`, // Dynamic border color
                            borderRadius: '8px',
                            textAlign: 'center',
                            flexShrink: 0, // Prevents flex items from shrinking
                          }}
                        >
                          <p style={{ fontSize: '12px', cursor: 'pointer',paddingBottom:'0px',color:`${colorCode}` }} onClick={() => onSuggestedMsgClick(question)}>
                            {question}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                    <div style={{ width: '95%'}}>

                      
                    <ChatInput 

                    userMessage={userMessage} 
                    setUserMessage={setUserMessage} 
                    handleSendMessage={handleButtonPress} 
                    handleSendImage={handleSendImage}
                    setSelectedImage={setSelectedImage}
                    handleSendVoice={handleSendVoice}
                    selectedImage={selectedImage}
                    audioBlob={audioBlob}
                    setAudioBlob={setAudioBlob}
                  />

                      
         
                  </div>
                  {!PoweredByJaweb? <div className=" mt-2 flex items-center justify-center">
                    <a className="text-gray-400" style={{fontSize:"70%"}} href="https://jaweb.me/" target="_blank" rel="noopener noreferrer">
                      Powered By Jaweb
                    </a>
                  </div> :<></> }
                 
                </div>
              </div>
            )}
          </div>
        ):null}
      </div>
    </div>
      )}
</ShadowWrapper>
    
  );
}

export default App;
