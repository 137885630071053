
import {ShoppingCartOutlined} from '@ant-design/icons'; // Import the loading icon

const ChatMessage = ({ message, colorCode,handleAddToCart }) => {
  if (!message) return null;

  // Split messages into parts using "|||"
  const messageParts = message.split("|||").map((part) => part.trim());
  
  // Function to extract product details
  const extractProductDetails = (text) => {
    const nameMatch = text.match(/Name:\s*(.+)/);
    const priceMatch = text.match(/Price:\s*(\$\d+\.\d{2})/);
    const linkMatch = text.match(/Link:\s*(https?:\/\/[^\s]+)/);
    const imgMatch = text.match(/img -\s*(https?:\/\/[^\s]+)/);
    const variantMatch = text.match(/VariantId:\s*(.+)/)

    // Return null if the message is NOT a product
    if (!nameMatch || !priceMatch || !linkMatch || !imgMatch) return null;

    return {
      name: nameMatch[1],
      price: priceMatch[1],
      link: linkMatch[1],
      image: imgMatch[1],
      variant_id:variantMatch[1]
    };
  };

  

  const formatTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a
          key={index}
          href={part.trim()} // Trim spaces around URLs
          style={{ color: colorCode }}
          target="_blank"
          rel="noopener noreferrer"
          className="underline break-words"
        >
          {part}
        </a>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <div className="space-y-4">
      {messageParts.map((part, index) => {
        const product = extractProductDetails(part);

        if (product) {
          // Render as a product card with left-aligned content
          return (
            <div key={index} className="flex items-center space-x-4 bg-white shadow-lg rounded-2xl p-4">
              {/* Left Image Section */}
              <div className="relative w-32 h-32 sm:w-40 sm:h-40">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-full object-cover rounded-xl cursor-pointer"
                    onClick={()=>{window.location.href=product.link}}
                  onError={(e) => console.error("Image failed to load:", e.target.src)}
                />
              </div>

              <div className="flex-1">
                <a  href={product.link} className="text-lg font-semibold text-gray-900">{product.name}</a>
                <p className="text-md font-medium text-gray-700">{product.price}</p>
                <div className="flex space-x-2 mt-3 items-center">
                  <a target="_blank" rel="noopener noreferrer">
                  <button className="bg-black text-white font-semibold px-4 py-2 rounded-lg hover:bg-gray-800 transition flex items-center gap-2"
                    style={{ backgroundColor: colorCode }}
                    onClick={()=>{
                      handleAddToCart(`name:${product.name},variantId:${product.variant_id},Link:${product.link}`)
                    }}
                    >
                        <div>
                        <ShoppingCartOutlined />

                        </div>
                          <div>
                          +
                          </div>
                      
                    </button>
                  </a>
            
                </div>
              </div>
            </div>
          );
        }

        return (
          <p key={index} className="mt-2 whitespace-pre-wrap break-words ">
            {formatTextWithLinks(part)}
          </p>
        );
      })}
    </div>
  );
};

export default ChatMessage;
