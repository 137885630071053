import { useState, useRef, useEffect } from "react";
import { Input, Tooltip } from "antd";
import {
  SmileOutlined,
  PictureOutlined,
  SendOutlined,
  CloseCircleOutlined,
  AudioOutlined,
  StopOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import WaveSurfer from "wavesurfer.js";

const ChatInput = ({
  userMessage,
  setUserMessage,
  handleSendMessage,
  handleSendImage,
  handleSendVoice,
  setSelectedImage,
  selectedImage,
  setAudioBlob,
  audioBlob,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const mediaRecorderRef = useRef(null);
  const fileInputRef = useRef(null);
  const audioChunks = useRef([]);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  // 📷 Handle Image Upload & Preview
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
  };

  const handleSelectEmoji = (emoji) => {
    setUserMessage((prev) => prev + emoji.native);
  };

  // 🎙️ Voice Record Function (Mobile-compatible)
  const handleVoiceRecord = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Voice recording is not supported on this device/browser.");
      return;
    }

    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mimeType = MediaRecorder.isTypeSupported("audio/webm")
          ? "audio/webm"
          : "audio/mp4"; // iOS Safari fallback

        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
        audioChunks.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) audioChunks.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunks.current, { type: mimeType });
          setAudioBlob(audioBlob);
          setIsRecording(false);
        
          // ✅ Stop the microphone from staying active
          if (mediaRecorderRef.current?.stream) {
            mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
          }
        };
        
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (err) {
        console.error("Microphone access failed:", err);
        alert("Unable to access the microphone. Please allow permission or try another browser.");
      }
    } else {
      mediaRecorderRef.current.stop();
    }
  };

  // 🎛️ Initialize Waveform
  useEffect(() => {
    if (audioBlob && waveformRef.current) {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }

      const url = URL.createObjectURL(audioBlob);
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#ccc",
        progressColor: "#7F28F8",
        cursorWidth: 0,
        barWidth: 3,
        barHeight: 1.5,
        barGap: 2,
        height: 40,
        interact: true,
      });

      wavesurfer.current.load(url);
      wavesurfer.current.on("finish", () => setIsPlaying(false));
    }
  }, [audioBlob]);

  const togglePlay = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  const handleCancelRecording = () => {
    setIsRecording(false);
    setAudioBlob(null);
    if (wavesurfer.current) wavesurfer.current.destroy();
  };

  return (
    <div className="relative flex items-center bg-white border border-gray-300 rounded-full px-3 py-2 shadow-sm">
      {/* 😄 Emoji Picker */}
      {showEmojiPicker && (
        <div className="absolute bottom-12 left-0 z-50">
          <Picker data={data} onEmojiSelect={handleSelectEmoji} />
        </div>
      )}

      {/* 🖼️ Image Upload */}
      <button className="text-gray-500 hover:text-gray-700 mx-2" onClick={() => fileInputRef.current.click()}>
        {selectedImage ? (
          <div className="relative">
            <img src={URL.createObjectURL(selectedImage)} alt="Selected" className="w-8 h-8 rounded-full object-cover" />
            <CloseCircleOutlined
              className="absolute -top-2 -right-2 text-red-500 bg-white rounded-full"
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedImage(null);
              }}
            />
          </div>
        ) : (
          <PictureOutlined style={{ fontSize: "20px" }} />
        )}
      </button>
      <input type="file" accept="image/*" ref={fileInputRef} onChange={handleImageUpload} className="hidden" />

      {/* 🎙️ Record Button */}
      <Tooltip title={isRecording ? "Stop Recording" : "Send Voice Note"}>
        <button
          className={`mx-2 ${isRecording ? "text-red-500" : "text-gray-500"} hover:text-gray-700`}
          onClick={handleVoiceRecord}
        >
          {isRecording ? <StopOutlined style={{ fontSize: "20px" }} /> : <AudioOutlined style={{ fontSize: "20px" }} />}
        </button>
      </Tooltip>

      {/* 🎛️ Input, Recorder, or Waveform */}
      <div className="flex-1 mx-2">
  {audioBlob ? (
    <div className="flex items-center bg-gray-100 px-4 py-2 rounded-lg w-full">
      <button className="text-purple-500 hover:text-purple-700 mr-3" onClick={togglePlay}>
        {isPlaying ? (
          <PauseCircleOutlined style={{ fontSize: "24px" }} />
        ) : (
          <PlayCircleOutlined style={{ fontSize: "24px" }} />
        )}
      </button>
      <div ref={waveformRef} className="w-full h-8" />
      {/* ❌ Show close only after recording is complete */}
      <CloseCircleOutlined
        className="text-red-500 text-lg cursor-pointer ml-3"
        onClick={handleCancelRecording}
      />
    </div>
  ) : isRecording ? (
    <div className="flex items-center justify-center bg-gray-100 px-4 py-2 rounded-lg w-full animate-pulse">
      {/* 🎤 Recording… text and stop icon only */}
      <span className="text-gray-600 font-medium mr-3"> Recording...</span>
   
    </div>
  ) : (
    <Input
      type="text"
      className="w-full border-none focus:ring-0 focus:outline-none text-gray-700"
      style={{ fontSize: "16px", lineHeight: 1.5 }}
      value={userMessage}
      onChange={(e) => setUserMessage(e.target.value)}
      placeholder="Message..."
      onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
    />
  )}
</div>


      {/* 😀 Emoji Button */}
      <button className="text-gray-500 hover:text-gray-700 mx-2" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
        <SmileOutlined style={{ fontSize: "20px" }} />
      </button>

      {/* 🚀 Send Button */}
      <button
        className="text-gray-500 hover:text-gray-700 mx-2"
        onClick={() => {
          if (selectedImage) {
            handleSendImage(selectedImage);
            removeSelectedImage();
          } else if (audioBlob) {
            handleSendVoice(audioBlob);
            handleCancelRecording();
          } else {
            handleSendMessage();
          }
        }}
      >
        <SendOutlined style={{ fontSize: "20px" }} />
      </button>
    </div>
  );
};

export default ChatInput;
